import React, { useState, useRef, useLayoutEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getData, postData } from '../../../api/data';
import Error from '../Error';
import { notify } from '../../../helpers';


const Edit = () => {
    const [savedIntroduction, setSaveIntroduction] = useState(null);
    const [savedDesignation, setSaveDesignation] = useState(null);
    const [savedAbout, setSaveAbout] = useState(null);
    const [fof, setFof] = useState(false);
    const introductionRef = useRef(null);
    const designationRef = useRef(null);
    const aboutRef = useRef(null);
    const navigate = useNavigate();

    const getDataFunc = async () => {
        const result = await getData();
        if (result?.status === 200) {
            setSaveIntroduction(result?.data[0]?.introduction);
            setSaveDesignation(result?.data[0]?.designation);
            setSaveAbout(result?.data[0]?.about);
        } else {

        }
    }

    useLayoutEffect(() => {
        const token = localStorage.getItem('token');
        console.log('token is invalid')
        if (!token) {
            setFof(true);
        } else {
            getDataFunc();
        }
    }, [])

    const handleSubmit = async (e) => {
        e.preventDefault();
        let data = {
            introduction: introductionRef?.current?.value,
            designation: designationRef?.current?.value,
            about: aboutRef?.current?.value
        }
        const result = await postData(data);
        if (result?.status == 200) {
            notify('Data Successfully updated', 'success');                
            navigate('/');
        }else{
            notify('Something went wrong', 'error');
        }
    }

    const handleLogout = () => {
        localStorage.removeItem('token');
        navigate('/');
    }

    return (
        <>
            <div className="container">
                {
                    !fof ? (
                        <>
                            <div className='d-flex align-center editHeader justify-content-between my-5'>
                                <button className="btn btn-light" onClick={() => navigate('/')}>Back</button>
                                <button className="btn btn-light" onClick={handleLogout}>Logout</button>
                            </div>
                            <div className="d-flex flex-column">
                                <form action="" className="editForm" onSubmit={handleSubmit}>
                                    <div className="my-2">Designation:</div>
                                    <input type="text" className='w-100' ref={designationRef} defaultValue={savedDesignation} />
                                    <div className="mt-4 mb-2">Introduction:</div>
                                    <input type="text" className='w-100' ref={introductionRef} defaultValue={savedIntroduction} />
                                    <div className="mt-4 mb-2">About:</div>
                                    <textarea className="w-100" name="" id="" cols="30" rows="8" ref={aboutRef} defaultValue={savedAbout}></textarea>
                                    <div className='mt-3'>
                                        <input type="submit" value="Submit" />
                                    </div>
                                </form>

                            </div>

                        </>
                    ) : (
                        <>
                            <Error />
                        </>
                    )
                }

            </div>
        </>
    )
}

export default Edit;