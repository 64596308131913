import React from "react";
import "animate.css/animate.min.css";
import harish_aboutsec from "../assets/images/about-pic.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
library.add(fab);

export default function About(props) {
  const about = props?.data?.savedAbout;
  return (
    <div className="about-section">
      <div className="container">
        <h2 className="about-heading text-center">About</h2>
        {/* <div className="row"> */}
          {/* <div className="col-md-6 col-12">
            <div className="image-thumbnail">
              <img
                className="about-pic"
                src={harish_aboutsec}
                alt="harish's pic"
                height="100%"
                width="100%"
              />
            </div>
          </div> */}
          <div className="mx-5 my-5 text-center">
            <p className="about-content">
              {about}
              {/* I'm Harish, a Frontend Developer with over a year of experience
              building successful websites. I enjoy building responsive websites
              and like to develop new things from scratch. I thrive on learning,
              constantly challenging myself, and doing interesting work with an
              impact. HTML, CSS, JavaScript and React are the technologies I use
              to create responsive websites that give users the best experience
              possible with their devices and browsers. The code I write is
              accessible, search engine friendly, easy to maintain, cross
              browser compatible, and performant. */}
            </p>
            <p>
              Below is a pdf with more information about my experience, skills,
              education, and more.
            </p>
            <a
              className="resumetag"
              href="/Harish_Front_end_Developer.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              My Resume <FontAwesomeIcon icon={["fab", "file-pdf"]} />
            </a>
          </div>
        {/* </div> */}
      </div>
    </div>
  );
}
