import { toast } from 'react-toastify';

const options = {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
};

export const notify = (data, type) => {
    switch (type) {
        case "success":
            toast.success(data, options)
            break;
        case "error":
            toast.error(data, options)
            break;
        case "warn":
            toast.warn(data, options)
            break;
        case "info":
            toast.info(data, options)
            break;
        default:
            toast(data, options)
    }
};