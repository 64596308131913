import React, {useEffect, useState} from "react";
import "./mainPage.scss";
import Header from "./components/header";
import MainContainer from "./components/mainContainer";
import About from "./components/about";
import { getData } from "./api/data";
import Footer from "./components/footer";
import { ToastContainer, toast } from 'react-toastify';

let date = new Date();
let time = date.getHours();
let year = date.getFullYear();
let greetings;

if (time < 12) {
  greetings = "Good Morning";
} else if (time < 16) {
  greetings = "Good Afternoon";
} else {
  greetings = "Good Evening";
}


function MainPage() {
  const [savedIntroduction, setSavedIntroduction] = useState(null);
  const [savedDesignation, setSavedDesignation] = useState(null);
  const [savedAbout, setSavedAbout] = useState(null);
  const getDataFunc = async() => {
    const result = await getData();
    if(result?.status === 200) {
      setSavedIntroduction(result?.data[0]?.introduction);
      setSavedDesignation(result?.data[0]?.designation);
      setSavedAbout(result?.data[0]?.about);
    }else{
      
    }
  } 
  
  useEffect(() => {
    getDataFunc();
  },[])

  return (
    <section>
      <Header greetings={greetings} />
      <MainContainer data={{savedIntroduction : savedIntroduction,savedDesignation : savedDesignation}} />
      <About data={{savedAbout: savedAbout}} />
      <ToastContainer
                    position="top-right"
                    autoClose={3000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="colored"
                />
      <Footer year={year} />
    </section>
  );
}

export default MainPage;
