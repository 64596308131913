import {useState, useEffect, useRef} from 'react';
import getSignIn from '../../../api/auth';
import userProfile from '../../../assets/images/userProfile.svg';
import showpassword from '../../../assets/images/showPassword.svg';
import showtext from '../../../assets/images/hidePassword.svg';
import './index.scss';
import { Link, useNavigate } from 'react-router-dom';
import { useCallback } from 'react';

const Login = () => {
    const username = useRef(null);
    const password = useRef(null);
    const [showPwd, setShowPwd] = useState(false);
    const [error,setError] = useState(false);
    const navigate = useNavigate();
    const handleSubmit = (e) => {
        e.preventDefault();
        userLogin();
    }

    const handleUserName = (e) => {
        username.current = e
    }

    const handlePassword = (e) => {
        password.current = e
    }

    const userLogin = async() => {
        const result = await getSignIn({username: username.current.value, password: password.current.value});
        if(result?.status === 200){
            localStorage.setItem('token', result?.accessToken);
            navigate('/edit/main');
        }else{
            password.current.value='';
            setError(true);
        }
    }

    const handleVisibility = () => {
        if(password.current.type === 'password'){
            password.current.type = 'text';
            setShowPwd(true);
        }else{
            password.current.type = 'password';
            setShowPwd(false);
        }
    }

    return (
    <>
            <div className="container-fluid loginContainer">
                <div className="loginContainer_body">
                    <div className="loginContainer_body_form col-10 col-md-5 col-lg-3">
                        <div className="loginContainer_body_form_logo">
                            <img src={userProfile} height="100" width="100" alt="userLogo" />
                            <div className="loginContainer_body_form_title">Sign In</div>
                        </div>
                        <form action="" onSubmit={handleSubmit}>
                                <label className="input-container_label" htmlFor="username">Username</label>
                            <div className="input-container">
                                <input type="text" name="username" ref={username} placeholder='ex: 123@gmail.com' id="username" required />
                            </div>
                                <label className="input-container_label" htmlFor="password">Password</label>
                            <div className="input-container d-flex justify-content-center align-items-center">
                                <input type="password" name="Password" ref={password} placeholder='enter password' id="password" required />
                                <div className="showPwdIcon" onClick={handleVisibility}>
                                <img src={showPwd ? showpassword : showtext} height="20" width="20" alt="show" />
                                </div>
                            </div>
                            {
                                error && <div className="errorMessage mt-2">Invalid Username or Password</div>
                            }                            
                            <div className="loginContainer_submit">
                                <input type="submit" />
                            </div>
                            <div className="returnHome">
                                <Link to="/">Return to Home</Link>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
    </>

    )};

export default Login;