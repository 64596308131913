import axiosInstance from "./index";

const Token = localStorage.getItem('token');

const authHeader = {
  'Authorization' : `Bearer ${Token}` // Token
}

const getData = () => axiosInstance({
        method: 'get',
        url: '/get-data',
      }).then(res => res.data).catch(err => err);

const postData = (data) => axiosInstance({
    method: 'post',
    url: '/data',
    headers: authHeader,
    data: data,
})      

export {getData, postData};