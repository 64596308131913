import React from "react";
import Lottie from 'react-lottie';
import sun from "../assets/lotties/sun.json";
import moon from "../assets/lotties/moon.json";
import { ThemeContext, themes } from "../contexts/ThemeContext";

export default function Header(props) {
  const [darkMode, setDarkMode] = React.useState(true);
  const sunOptions = {
    loop: true,
    autoplay: true,
    animationData: sun,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };
  const moonOptions = {
    loop: true,
    autoplay: true,
    animationData: moon,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };
  return (
    <div className="header-section">
      <div className="container-fluid  main-nav">
        <div className="d-flex p-2 justify-content-between">
          <div className="greetings_elem d-flex align-items-center">
            <div className="wish">Hi</div>
            <span className="wave">👋</span>
            <h2 className="greetings_wish">{props.greetings}</h2>
          </div>
          <ThemeContext.Consumer>
              {({ changeTheme }) => (
                <>
                <div className="d-flex  align-items-center">
                <Lottie 
                      options={moonOptions}
                        height={30}
                        width={30}
                    />
                <input
                  className="themeChanger"
                  id="checkbox"
                  color="link"
                  type="checkbox"
                  onChange={() => {
                    setDarkMode(!darkMode);
                    changeTheme(darkMode ? themes.light : themes.dark);
                  }}
                ></input>
                <Lottie 
                  options={sunOptions}
                    height={35}
                    width={35}
                />
                </div>
                </>

              )}
            </ThemeContext.Consumer>
        </div>
      </div>
    </div>
  );
}
