import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
library.add(fab);
export default function Footer(props) {
  return (
    <div className="footer-section">
      <div className="text-center footer-contact">
        <div className="mb-1 email-contact">
          To get in touch, email:{" "}
          <a className="mailto" href="mailto:koninti9@gmail.com">
            koninti9@gmail.com
          </a>
        </div>
        <div className="mb-1 social-contact">
          Follow Me :
          <a
            className="footer_social-icon linkedin-icon"
            href="https://www.linkedin.com/in/harish-k-40a078199/"
            target="_blank"
            rel="noreferrer"
            title="Linkedin"
          >
            <FontAwesomeIcon icon={["fab", "linkedin"]} />
          </a>
          <a
            className="footer_social-icon fb-icon"
            href="https://www.facebook.com/koninti.harish"
            target="_blank"
            rel="noreferrer"
            title="Facebook"
          >
            <FontAwesomeIcon icon={["fab", "facebook"]} />
          </a>
          <a
            className="footer_social-icon instagram-icon"
            href="https://www.instagram.com/harish_koninti/?hl=en"
            target="_blank"
            rel="noreferrer"
            title="Instagram"
          >
            <FontAwesomeIcon icon={["fab", "instagram"]} />
          </a>
          <a
            className="footer_social-icon twitter-icon"
            href="https://twitter.com/kharish1997"
            target="_blank"
            rel="noreferrer"
            title="Twitter"
          >
            <FontAwesomeIcon icon={["fab", "twitter"]} />
          </a>
          <a
            className="footer_social-icon github-icon"
            href="https://github.com/harishkoninti"
            target="_blank"
            rel="noreferrer"
            title="Github"
          >
            <FontAwesomeIcon icon={["fab", "github"]} />
          </a>
        </div>
      </div>
      {/* <div className="copyright text-center mb-2">
        Copyright &copy; {props.year}&nbsp;Harish K
      </div> */}
    </div>
  );
}
