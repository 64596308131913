import React, {useState, useEffect, useCallback} from "react";
import harishpic_home from "../assets/images/harishpic_home.png";
import react_logo from "../assets/images/reactlogo.png";
import html_logo from "../assets/images/htmllogo.png";
import css_logo from "../assets/images/csslogo.png";
import js_logo from "../assets/images/jslogo.png";
import jquery_logo from "../assets/images/jquerylogo.png";
import bootstrap_logo from "../assets/images/bootstraplogo.png";
import sass_logo from "../assets/images/sasslogo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import "animate.css/animate.min.css";
import Skeleton from 'react-loading-skeleton'
library.add(fab);

export default function MainContainer(props) {
  const introduction = props?.data?.savedIntroduction;
  const designation = props?.data?.savedDesignation;
  return (
    <div className="mainContainer-section">
      <div className="container-fluid  main-container"  style={{display:"flex", justifyContent:"center", flexDirection:"column"}}>
        <div className="row flex-row">
          <div className="col-md-8 col-12 flex-row_first d-flex align-items-center justify-content-center mt-5">
            <div className="name_design">
              I'm <span className="fw-bold"> Harish K,</span> <br />
              {
                designation ? (
                  <>
                  <div className="text_highlighter type d-none d-md-block">{designation}</div>
                  <div id="crow">|</div>
                  <div className="clearfix"></div>
                  </>
                ) : (
                  <Skeleton count={1} baseColor="#202020" highlightColor="#444" />
                )
              }
              <div className="text_highlighter d-block d-md-none">{designation || <Skeleton count={1} baseColor="#202020" highlightColor="#444" />}</div>
              <p className="intro_about">
                {introduction || <Skeleton count={3} baseColor="#202020" highlightColor="#444" />}
              </p>
            </div>
          </div>
          <div className="col-md-4 col-12 flex-row_second">
            <div className="imageContainer box-shadow">
              <img src={harishpic_home} alt="harish's image" loading="lazy" height="fit-content" width="fit-content" />
              {/* <img src={harishpic_home} /> */}
            </div>
          </div>
        </div>

        <div className="social_elem d-none d-md-flex flex-column">
          <div className="social_elembef"></div>
          <a
            className="main-icon linkedin-icon"
            href="https://www.linkedin.com/in/harish-k-40a078199/"
            target="_blank"
            rel="noreferrer"
            title="Linkedin"
          >
            <FontAwesomeIcon icon={["fab", "linkedin"]} />
          </a>
          <a
            className="main-icon fb-icon"
            href="https://www.facebook.com/koninti.harish"
            target="_blank"
            rel="noreferrer"
            title="Facebook"
          >
            <FontAwesomeIcon icon={["fab", "facebook"]} />
          </a>
          <a
            className="main-icon instagram-icon"
            href="https://www.instagram.com/harish_koninti/?hl=en"
            target="_blank"
            rel="noreferrer"
            title="Instagram"
          >
            <FontAwesomeIcon icon={["fab", "instagram"]} />
          </a>
          <a
            className="main-icon twitter-icon"
            href="https://twitter.com/kharish1997"
            target="_blank"
            rel="noreferrer"
            title="Twitter"
          >
            <FontAwesomeIcon icon={["fab", "twitter"]} />
          </a>
          <a
            className="main-icon github-icon"
            href="https://github.com/harishkoninti"
            target="_blank"
            rel="noreferrer"
            title="Github"
          >
            <FontAwesomeIcon icon={["fab", "github"]} />
          </a>
          <div className="social_elembef"></div>
        </div>

        <div className=" tech_section d-flex align-items-center justify-content-center">
          <div className="techStack box-shadow d-flex align-items-center justify-content-between">
            <img
              className="tech_icons"
              src={react_logo}
              alt="React"
              height="65px"
              width="80px"
              loading="lazy"
            />
            <img
              className="tech_icons"
              src={html_logo}
              alt="Html"
              height="85px"
              width="90px"
              loading="lazy"
            />
            <img
              className="tech_icons"
              src={css_logo}
              alt="CSS"
              height="85px"
              width="90px"
              loading="lazy"
            />
            <img
              className="tech_icons"
              src={js_logo}
              alt="Javascript"
              height="75px"
              width="70px"
              loading="lazy"
            />
            <img
              className="tech_icons"
              src={jquery_logo}
              alt="Jquery"
              height="80px"
              width="90px"
              loading="lazy"
            />
            <img
              className="tech_icons"
              src={bootstrap_logo}
              alt="Bootstrap"
              height="90px"
              width="90px"
              loading="lazy"
            />
            <img
              className="tech_icons"
              src={sass_logo}
              alt="Sass"
              height="80px"
              width="90px"
              loading="lazy"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
