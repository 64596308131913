import { useNavigate } from "react-router-dom";
const Error = () => {
    const navigate = useNavigate();
    return (
        <>
            <div className="d-flex justify-content-center align-items-center flex-column section404" style={{ height: '100dvh' }}>
                <div className="text404">404</div>
                <div className="subText404" onClick={() => navigate('/')}>Return to Home</div>
            </div>
        </>
    )
}

export default Error;