import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import MainPage from "./mainPage";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ThemeContextWrapper from "./Theme/ThemeWrapper";
import Login from "./components/Screens/Login";
import Edit from "./components/Screens/Edit";
import Error from "./components/Screens/Error";
import 'react-loading-skeleton/dist/skeleton.css';
import 'react-toastify/dist/ReactToastify.css';

<style>
  @import
  url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600&display=swap');
</style>;

const doc = document.documentElement
doc.style.setProperty('--app-height', `${window.innerHeight}px`)
// window.addEventListener('resize', appHeight)

ReactDOM.render(
  <ThemeContextWrapper>
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<MainPage />} />
      <Route path="/admin/login" element={<Login />} />
      <Route path="/edit/main" element={<Edit />} />
      <Route path="*" element={<Error />} />
    </Routes>
    <React.StrictMode></React.StrictMode>
  </BrowserRouter>
  </ThemeContextWrapper>
,
  document.getElementById("root")
);
